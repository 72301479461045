import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { adminRoutes } from "./routers/index";
import {isLogined} from './helper/localdbhelper'
import Frame from "./comments/frame/Frame";

class  App extends React.Component {
  state={
    collapsed:false
  }
  render(){
    return (
      isLogined()?
      <Frame>
         <Switch>
              {adminRoutes.map((route) => {
                  if(route.child&&route.child.length>0){
                    return route.child.map((route)=>{
                        return (
                            <Route
                              key={route.path}
                              path={route.path}
                              exact={route.exact}
                              render={(routeProps) => {
                                return <route.component {...routeProps} />;
                              }}
                            />
                          );
                     })
                  }else{
                    return (
                        <Route
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          render={(routeProps) => {
                            return <route.component {...routeProps} />;
                          }}
                        />
                      );
                  }
              })}
              {/* <Redirect to={adminRoutes[0].path} from="/admin"/> */}
            </Switch>
      </Frame>:<Redirect to="/login"/>
    );
  }
 
}

export default App;
