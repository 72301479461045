import Loadable from 'react-loadable';
import { Spin } from 'antd';

const PageList = Loadable({
  loader: () => import('../page/page_template/page_list'),
  loading: Spin,
});

const PageType = Loadable({
  loader: () => import('../page/page_template/page_type'),
  loading: Spin,
});

const PageLogin = Loadable({
  loader: () => import('../page/page_login/page_login'),
  loading: Spin,
});
const PageHome = Loadable({
  loader: () => import('../page/page_home/page_home'),
  loading: Spin,
});
const PageLanguage = Loadable({
  loader: () => import('../page/page_resources/page_lauguage'),
  loading: Spin,
});
const PageImage = Loadable({
  loader: () => import('../page/page_resources/page_image'),
  loading: Spin,
});
const PageFont = Loadable({
  loader: () => import('../page/page_resources/page_font'),
  loading: Spin,
});
const PageCagegory = Loadable({
  loader: () => import('../page/page_resources/page_category'),
  loading: Spin,
});
const PageResourceType = Loadable({
  loader: () => import('../page/page_resources/page_resourcetype'),
  loading: Spin,
});
const PageDevice = Loadable({
  loader: () => import('../page/page_device/page_device'),
  loading: Spin,
});
const PageMohe = Loadable({
  loader: () => import('../page/page_mohe/page_mohe'),
  loading: Spin,
});
export const mainRoutes = [{
  path: "/login",
  component: PageLogin,
}];
export const adminRoutes = [
  
  {
    title: '首页',
    icon: 'icon-home',
    isshow: true,
    path: "/admin/home",
    component: PageHome,
  },
  {
    title: '素材管理',
    icon: 'icon-duosucai',
    isshow: true,
    child:[{
      title: '语言管理',
      icon: 'icon-yuyan',
      isshow: true,
      component: PageLanguage,
      path: "/admin/language",
    },
    {
      title: '资源类型管理',
      icon: 'icon-fenlei',
      isshow: true,
      component: PageResourceType,
      path: "/admin/resourcetype",
    },
    {
      title: '分类管理',
      icon: 'icon-fenlei',
      isshow: true,
      component: PageCagegory,
      path: "/admin/category",
    },
    {
      title: '图片管理',
      icon: 'icon-tupian',
      isshow: true,
      component: PageImage,
      path: "/admin/image",
    },
    {
      title: '字体管理',
      icon: 'icon-zitidaxiao',
      isshow: true,
      component: PageFont,
      path: "/admin/font",
    },
  ]
  },
  {
    title: '设备管理',
    icon: 'icon-fax-full',
    isshow: true,
    child:[
      {
        title: '打印机管理',
        icon: 'icon-fax-full',
        isshow: true,
        component: PageDevice,
        path: "/admin/print_device",
      },{
        title: '墨盒管理',
        icon: 'icon-moheguanli',
        isshow: true,
        component: PageMohe,
        path: "/admin/mohe",
      },
    ]
  },
  {
    title: '模板管理',
    icon: 'icon-mobansheji',
    isshow: true,
    child:[
      {
        title: '类型管理',
        icon: 'icon-fax-full',
        isshow: true,
        component: PageType,
        path: "/admin/templatetype",
      },{
        title: '模版管理',
        icon: 'icon-moheguanli',
        isshow: true,
        component: PageList,
        path: "/admin/templatelist",
      },
    ]
  },  
  {
    title: '系统设置',
    icon: 'icon-shezhi',
    isshow: true,
    component: PageLanguage,
  },
];

