import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router, Switch, Route ,Redirect} from "react-router-dom";
import {mainRoutes} from './routers/index'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('en');
ReactDOM.render(
  <Router >
    <Switch>
      <Route path="/admin" render={(routeProps) => <ConfigProvider locale={zhCN}><App {...routeProps} /></ConfigProvider>} />
        {mainRoutes.map((route) => {
            return <Route key={route.path} {...route} />;
        })}
      <Redirect to="/login" from="/"/>
      <Redirect to="/404"/>
    </Switch>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
