import Axios from 'axios'
import base_url from '../config/netconfig';
const API_PRE="api/v1/"
const http=Axios.create({
    timeout:1000*60*5,
})
http.interceptors.request.use((config)=>{
    console.log(config)
    return config;
},(error)=>{
    return Promise.reject(error)
})
export const get=(url,cb)=>{
    return http.get(API_PRE+url).then(res=>{
        let body=res.data
        if(body.code===1){
            cb(null,body.data);
        }else{
            cb(body.message,null)
        }
    }).catch(err=>{
        cb('服务器未知错误'+err,null)
    })
}
export const post=(url,param,cb)=>{
    return http.post(API_PRE+url,param).then(res=>{
        let body=res.data
        console.log(body)
        if(body.code===1){
            cb(null,body.data);
        }else{
            cb(body.message,null)
        }
    }).catch(err=>{
        console.log(err)
        cb('服务器未知错误',null)
    })
}
export const postFile=(url,param)=>{
    if('api/admin/login'===url){
        return http.post(url,param)
    }else{
        return http.post(url,param,{
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}